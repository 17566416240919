<template>
  <!--  // TODO -> API torni el breadcrumb-->
  <page-breadcrumb :breadcrumbs="breadcrumbWithCatalog" />
</template>

<script>
import PageBreadcrumb from "../../pages/components/PageBreadcrumb";
import {mapGetters} from "vuex";

export default {
  components: {
    PageBreadcrumb: PageBreadcrumb,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      breadcrumb: "breadcrumb",
    }),
    breadcrumbWithCatalog() {
      return [...[{
        name: "Catálogo",
        to: "/catalogo",
      }],
        ...this.categoryBreadCrumb,
        ...[{
          name: this.product.name,
        }]
      ];
    },
    categoryBreadCrumb() {
      if (!this.product.breadcrumb) return []
      return this.product.breadcrumb?.map(item => { return { name: item.name, to: item.to || '/categoria/' + item.slug}  })
    },
  }
};
</script>
