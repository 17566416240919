<template>
  <div>
    <main v-show="productReady">
      <!--<product-sticky :product="product"></product-sticky>-->

      <div class="header-search">
        <div class="bb-container">
          <SearchBar v-show="displaySearchBar"></SearchBar>
        </div>
      </div>

      <div class="bb-container">
        <div class="product-breadcrumb"><product-breadcrumb :product="product" /></div>

        <div id="Fitxa" class="g-product">
          <div class="g-product__left">
            <div class="g-product__pictures">
              <ShareProduct :product="product"/>
              <no-ssr>
                <product-images :ready="productReady" :product="product"></product-images>
              </no-ssr>
            </div>
            <div
                class="g-product__content"
                v-if="this.$mq === 'm992' || this.$mq === 'm1200'"
            >
              <product-abstract :product="product"></product-abstract>
              <product-description :product="product"></product-description>
            </div>
          </div>

          <div class="g-product__summary">
            <product-details v-show="productReady" :product="product"></product-details>
          </div>
        </div>

        <div
            class="g-product-content"
            v-if="this.$mq === 'm480' || this.$mq === 'm576' || this.$mq === 'm768'"
        >
          <product-abstract :product="product"></product-abstract>
          <product-description :product="product"></product-description>
        </div>

        <LazyHydrate when-visible>
          <entity-collections :entity="product"></entity-collections>
        </LazyHydrate>
      </div>

      <LazyHydrate when-visible>
        <!-- No eliminar div buit Necessari per evitar errors SSR i hidratació-->
        <div>
          <product-related :product="product"></product-related>
        </div>
      </LazyHydrate>

      <LazyHydrate when-visible>
        <section
            class="product-related-posts"
            v-if="product.posts && product.posts.length > 0"
        >
          <div class="bb-container">
            <h5 class="product-related-posts__title">Artículos y guías relacionados</h5>
          </div>
          <div class="bb-container">
            <div class="product-related-posts__content">
              <div class="g-post-slider">
                <related-posts
                    main-class="g-post-slider__items"
                    :posts="product.posts"
                ></related-posts>
              </div>
            </div>
          </div>
        </section>
      </LazyHydrate>

      <LazyHydrate when-visible>
        <catalogue-porque />
      </LazyHydrate>
      <LazyHydrate when-visible>
        <categories-directory></categories-directory>
      </LazyHydrate>
    </main>
    <main v-show="!productReady">
      <ProductPageSkeleton></ProductPageSkeleton>
    </main>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import ProductSticky from "./components/ProductSticky";
import ProductBreadcrumb from "./components/ProductBreadcrumb";
import ProductImages from "./components/ProductImages";
import ProductDetails from "./components/ProductDetails";
import ProductAbstract from "./components/ProductAbstract";
import ProductDescription from "./components/ProductDescription";
import ShareProduct from "../../components/ShareProduct";
import DisplaySearchBar from "../mixins/DisplaySearchBar";
import LazyHydrate from "vue-lazy-hydration";
import { $formatUrlhelloBB } from "../../plugins/string";
import ProductPageSkeleton from "../../components/skeleton/ProductPageSkeleton";
import NoSSR from "vue-no-ssr";

export default {
  name: "ProductPage",
  mixins: [DisplaySearchBar],
  components: {
    // ProductSticky,
    "no-ssr": NoSSR,
    ProductBreadcrumb,
    ProductImages,
    ProductDetails,
    ProductAbstract,
    ProductDescription,
    ProductPageSkeleton,
    EntityCollections: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "EntityCollections" */ "../catalogue/components/EntityCollections"
      ),
    ProductRelated: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "ProductRelated" */ "./components/ProductRelated"
      ),
    RelatedPosts: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "RelatedPosts" */ "../catalogue/components/RelatedPosts"
      ),
    SearchBar: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "SearchBar" */ "../../components/SearchBar"
      ),
    CategoriesDirectory: () =>
      import(
        /* webpackChunkName: "CategoriesDirectory" */
        /* webpackPrefetch: true */ "../catalogue/components/CategoriesDirectory"
      ),
    CataloguePorque: () =>
      import(
        /* webpackChunkName: "CataloguePorque" */
        /* webpackPrefetch: true */ "../catalogue/components/CataloguePorque"
      ),
    ShareProduct,
    LazyHydrate,
  },
  metaInfo() {
    return {
      title: this.getTitle(),
      meta: [
        {
          name: "description",
          content: this.getDescription(),
        },
        { name: "referrer", content: "no-referrer-when-downgrade" },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: this.getTitle() },
        { name: "twitter:description", content: this.getDescription() },
        { name: "twitter:image", content: 'https://www.hellobb.net/hbb-card.jpg' },
        { name: "twitter:url", content: this.currentUrl() },
        { name: "twitter:label1", content: "Written by" },
        { name: "twitter:data1", content: "HelloBB" },
        { name: "twitter:label2", content: "HelloBB" },
        { name: "twitter:data2", content: this.getTitle()},

        { property: "og:title", content: this.getTitle() },
        { property: "og:site_name", content: "HelloBB" },
        { property: "og:type", content: "article" },
        { property: "og:image", content: this.photoUrl },
        { property: "og:locale", content: "es_ES" },
        //{ property: "og:image:width", content: "1280" },
        //{ property: "og:image:height", content: "775" },
        { property: "og:description", content: this.getDescription() },
        { property: "og:url", content: this.currentUrl() },
        // { property: "article:published_time", content: this.post.published_at },
        // { property: "article:modified_time", content: this.post.updated_at },
        // { property: "article:tag", content: this.post.primary_tag?.name },
        { property: "article:publisher", content: "https://www.facebook.com/holahelloBB" },
      ],
      link: [
        {
          rel: "canonical",
          href:
            "https://www.hellobb.net" +
            (this.product.metaInfo?.title
              ? "/catalogo/" +
                $formatUrlhelloBB(this.product.metaInfo?.title) +
                "/" +
                this.product.id
              : this.$route.path),
        },
      ],
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org/",
            "@type": "Product",
            name: this.product.name,
            image: this.getImages(),
            description: this.product?.manufacturerDescription?.replace(/<[^>]*>?/gm, ""),
            brand: {
              "@type": "Brand",
              name: this.product.brand,
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: this.product.rating,
              ratingCount:
                parseInt(this.product.ratingCount) !== 0 ? this.product.ratingCount : 0,
            },
            offers: {
              "@type": "AggregateOffer",
              offerCount: this.product.productRetailers?.length,
              lowPrice: this.product.minPrice,
              highPrice: this.product.maxPrice,
              priceCurrency: "EUR",
            },
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: this.breadcrumbArray,
          },
        },
      ],
    };
  },
  data() {
    return {
      productReady: false,
    };
  },
  computed: {
    ...mapGetters({
      product: "product",
      isAuthenticated: "isAuthenticated",
    }),
    photoPath() {
      if (this.product?.photos && this.product?.photos?.length > 0) return this.product?.photos[0].path
      else return null
    },
    photoUrl() {
      return this.photoPath || ""
    },
    title() {
      if (this.product.metaInfo != null) {
        if (this.product.metaInfo.title === "") return this.product.brand;
        return this.product.metaInfo?.title;
      } else {
        return this.product.brand;
      }
    },
    description() {
      if (this.product.metaInfo != null) {
        return this.product.metaInfo.description;
      } else {
        return this.product.brand;
      }
    },
    model() {
      return this.product.model.length > 80
        ? this.product.model.substring(0, 80) + "..."
        : this.product.model;
    },
    breadcrumbArray() {
      let bcArray = [];
      bcArray.push({
        "@type": "ListItem",
        position: 1,
        name: this.product.mainCategory,
        item:
          "https://www.hellobb.net/categoria/" +
          this.product.breadcrumb?.[0]?.slug
      });
      let position = 1;
      if (this.product.subCategory1 != null) {
        position++;
        bcArray.push({
          "@type": "ListItem",
          position: 2,
          name: this.product.subCategory1,
          item:
            "https://www.hellobb.net/categoria/" +
            this.product.breadcrumb?.[1].slug
        });
      }
      if (this.product.subCategory2 != null) {
        position++;
        bcArray.push({
          "@type": "ListItem",
          position: 3,
          name: this.product.subCategory1,
          item:
            "https://www.hellobb.net/categoria/" +
            this.product.breadcrumb?.[2].slug
        });
      }
      bcArray.push({
        "@type": "ListItem",
        position: position + 1,
        name: this.product.name,
      });
      return bcArray;
    },
  },
  methods: {
    currentUrl() {
      return process.env.APP_URL.substring(0, process.env.APP_URL.length - 1) + this.$route.path;
    },
    getTitle() {
      let a = this.$cutText(
          this.product.metaInfo?.title ? this.product.metaInfo.title : this.product.name,
          70,
          "..."
      )
      //remove spaces at the beginning and end of the string
      a = a.replace(/^\s+|\s+$/g, '');
      return a + " | HelloBB";
    },
    getDescription() {
      return this.$cutText(
          this.product.metaInfo?.description
              ? this.product.metaInfo?.description
              : this.product.manufacturerDescription,
          160,
          "..."
      )
    },
    async getProduct(route = this.$route) {
      await this.$store.dispatch("getProduct", route.params.id);
    },
    getImages() {
      if(this.product?.shopifyHandle){
        return this.product?.photos?.map(function (item) {
          return item["path"];
        })
      }
      return this.product?.photos?.map(function (item) {
        return "https://images.hellobb.net/" + item["path"];
      });
    },
    async getProductAtClientSide(route = this.$route) {
      if (parseInt(this.product.id) !== parseInt(route.params.id)) {
        try {
          await this.getProduct(route);
        } catch (error) {
          if (error.response?.status === 404) {
            await this.$router.push({ name: "notfound" });
          }
          throw error;
        }
      }
    },
    trackProductPage() {
      if (this.isAuthenticated) {
        let subCategory = "";
        let subCategory2 = "";
        if (this.product?.subCategory1 != null)
          subCategory = ">" + this.product?.subCategory1;
        if (this.product?.subCategory2 != null)
          subCategory2 = ">" + this.product?.subCategory2;
        window.mixpanel.track("Veure pàgina objecte", {
          "Item Name": this.product?.name,
          "Item Category": this.product?.mainCategory + subCategory + subCategory2,
          "Num Retailers": this.product?.productRetailers?.length,
          Rating: this.product?.rating,
          Priority: this.product?.priority,
        });
      }
    },
    async prepareProduct(route = this.$route) {
      this.productReady = false;
      await this.getProductAtClientSide(route);
      this.productReady = true;

      this.trackProductPage();
    },
  },
  async serverPrefetch() {
    try {
      await this.getProduct();
    } catch (error) {
      if (error.response?.status === 404) {
        await this.$router.push({ name: "notfound" });
      }
      throw error;
    }
    this.productReady = true
  },
  async mounted() {
    await this.prepareProduct();
    if (this.product.metaInfo?.title && !this.$route.path.includes("/catalogo/")) {
      window.location.replace(
        process.env.APP_URL +
          "catalogo/" +
          $formatUrlhelloBB(this.product.metaInfo?.title) +
          "/" +
          this.product.id
      );
      window.mixpanel.track("Redirect JS", {
        "Product Id": this.product.id,
        "Source URL": document.referrer,
      });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    await this.prepareProduct(to);
    next();
  },
  async beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    next(async (vm) => {
      if (
        (from.name === "product" && to.name === "product-new") ||
        (from.name === "product-new" && to.name === "product")
      ) {
        await vm.prepareProduct(to);
      }
    });
  },
};
</script>
