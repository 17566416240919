<template>
  <div class="share-product" @click="share">
    <img src="../assets/img/ico/ico-share-url.svg" alt="" />
    <modal-dialog :show="show" @close="close" target-class="modal__card--mini">
      <div class="share-list">
        <div class="share-list__title">
          <h3 class="header-3">Comparte este objeto</h3>
        </div>

        <div class="share-list__info">
          <p>Copia el siguiente enlace y compártelo con quien quieras.</p>
        </div>
        <div class="share-list__url">{{ guestProductUrl }}</div>
        <div class="share-list__actions">
          <button
            class="button button--primary button--ico"
            @click="copyLink()"
          >
            <span v-if="copied">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5917 6.00822C15.5142 5.93011 15.4221 5.86811 15.3205 5.82581C15.219 5.7835 15.11 5.76172 15 5.76172C14.89 5.76172 14.7811 5.7835 14.6796 5.82581C14.578 5.86811 14.4858 5.93011 14.4084 6.00822L8.20004 12.2249L5.59171 9.60822C5.51127 9.53052 5.41632 9.46942 5.31227 9.42842C5.20823 9.38742 5.09713 9.36731 4.98531 9.36924C4.87349 9.37118 4.76315 9.39512 4.66058 9.4397C4.55802 9.48427 4.46524 9.54862 4.38754 9.62905C4.30984 9.70949 4.24875 9.80444 4.20774 9.90848C4.16674 10.0125 4.14663 10.1236 4.14856 10.2354C4.1505 10.3473 4.17444 10.4576 4.21902 10.5602C4.2636 10.6627 4.32794 10.7555 4.40837 10.8332L7.60837 14.0332C7.68584 14.1113 7.77801 14.1733 7.87956 14.2156C7.98111 14.2579 8.09003 14.2797 8.20004 14.2797C8.31005 14.2797 8.41897 14.2579 8.52052 14.2156C8.62207 14.1733 8.71424 14.1113 8.79171 14.0332L15.5917 7.23322C15.6763 7.15518 15.7438 7.06047 15.79 6.95506C15.8361 6.84964 15.86 6.7358 15.86 6.62072C15.86 6.50563 15.8361 6.3918 15.79 6.28638C15.7438 6.18096 15.6763 6.08625 15.5917 6.00822Z"
                  fill="#1E0028"
                />
              </svg>

              Enlace copiado
            </span>
            <span v-else>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3333 16.6667H6.66667C6.00363 16.6667 5.36774 16.4034 4.8989 15.9345C4.43006 15.4657 4.16667 14.8298 4.16667 14.1667V5.83342C4.16667 5.6124 4.07887 5.40044 3.92259 5.24416C3.76631 5.08788 3.55435 5.00008 3.33333 5.00008C3.11232 5.00008 2.90036 5.08788 2.74408 5.24416C2.5878 5.40044 2.5 5.6124 2.5 5.83342V14.1667C2.5 15.2718 2.93899 16.3316 3.72039 17.113C4.50179 17.8944 5.5616 18.3334 6.66667 18.3334H13.3333C13.5543 18.3334 13.7663 18.2456 13.9226 18.0893C14.0789 17.9331 14.1667 17.7211 14.1667 17.5001C14.1667 17.2791 14.0789 17.0671 13.9226 16.9108C13.7663 16.7545 13.5543 16.6667 13.3333 16.6667ZM17.5 7.45008C17.4913 7.37353 17.4746 7.29811 17.45 7.22508V7.15008C17.4099 7.0644 17.3565 6.98564 17.2917 6.91675L12.2917 1.91675C12.2228 1.85193 12.144 1.79848 12.0583 1.75841H11.9833L11.7167 1.66675H8.33333C7.67029 1.66675 7.03441 1.93014 6.56557 2.39898C6.09673 2.86782 5.83333 3.50371 5.83333 4.16675V12.5001C5.83333 13.1631 6.09673 13.799 6.56557 14.2678C7.03441 14.7367 7.67029 15.0001 8.33333 15.0001H15C15.663 15.0001 16.2989 14.7367 16.7678 14.2678C17.2366 13.799 17.5 13.1631 17.5 12.5001V7.50008C17.5 7.50008 17.5 7.50008 17.5 7.45008ZM12.5 4.50841L14.6583 6.66675H13.3333C13.1123 6.66675 12.9004 6.57895 12.7441 6.42267C12.5878 6.26639 12.5 6.05443 12.5 5.83342V4.50841ZM15.8333 12.5001C15.8333 12.7211 15.7455 12.9331 15.5893 13.0893C15.433 13.2456 15.221 13.3334 15 13.3334H8.33333C8.11232 13.3334 7.90036 13.2456 7.74408 13.0893C7.5878 12.9331 7.5 12.7211 7.5 12.5001V4.16675C7.5 3.94573 7.5878 3.73377 7.74408 3.57749C7.90036 3.42121 8.11232 3.33341 8.33333 3.33341H10.8333V5.83342C10.8333 6.49646 11.0967 7.13234 11.5656 7.60118C12.0344 8.07002 12.6703 8.33342 13.3333 8.33342H15.8333V12.5001Z"
                  fill="#1E0028"
                />
              </svg>

              Copiar enlace
            </span>
          </button>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from "./ui/ModalDialog";
import { mapGetters } from "vuex";

export default {
  name: "ShareProduct",
  components: {
    ModalDialog,
  },
  props: {
    sharing: {
      type: Boolean,
      default: true,
    },
    product: {
      type: Object,
      required: true,
    },
    listId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      show: false,
      copied: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      myList: "myList"
    }),
    guestProductUrl() {
      return this.$utils.removeDoubleSlashes(
        process.env.APP_URL + "/" + this.$route.path
      );
    },
    productName() {
      return this.product.name;
    },
  },
  methods: {
    async getList() {
      this.loading = true;
      await this.$store.dispatch("getListTuCuenta");
      this.loading = false;
    },
    close() {
      this.copied = false;
      this.show = false;
    },
    async copyLink() {
      try {
        await this.$copyText(this.guestProductUrl);
      } catch (error) {
        console.log(error);
      }
      this.copied = true;
    },
    async share() {
      let listId = this.listId;
      if (this.isAuthenticated && listId === 0) {
        await this.getList()
        listId = this.myList.id
      }
      if(this.isAuthenticated && this.listId !== 0) window.mixpanel.identify(this.listId);
      window.mixpanel.track("Compartir objecte", {
        'Object name': this.product.name,
        'List': this.listId,
        'Role': this.isAuthenticated ? 'Propietari' : (this.listId ? 'Regalador' : 'Visitant'),
        'Share client': this.$store.state.cookies.sourceApp ? 'App' : 'Webapp',
        'Share platform': this.$getPlatform()
      });
      if (navigator.share) {
        try {
          await navigator.share({
            title: this.productName,
            url: this.guestProductUrl,
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        if (this.sharing) this.show = true;
        else this.$emit("share");
      }
    },
  },
};
</script>
